import { Card } from "primereact/card";
import "../../css/components/dashboard.css";
import { forwardRef, useEffect, useRef, useState } from "react";
import {
  getRatingsByKpiOfLoggedInUser,
  getTopPerformedEmployee,
} from "../../service/performance/PerformanceService";
import { useDispatch, useSelector } from "react-redux";
import { formatInitials } from "../../shared/functions/FormatInitials";
import { Toolbar } from "primereact/toolbar";
import { Paginator, PaginatorPageChangeEvent } from "primereact/paginator";
import { AppModeState } from "../../dtos/common/AppModeState";
import { AppMode } from "../../data/AppMode";
import { SelectButton } from "primereact/selectbutton";
import { Button } from "primereact/button";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { Calendar } from "primereact/calendar";
import { DashboardInputDto } from "../../dtos/dashboard/DashboardInputDto";
import { ISTToDate } from "../../shared/functions/ISTToDate";
import { Checkbox } from "primereact/checkbox";
import {
  getTopBilledEmployee,
  getTopTrackingProjects,
  getTopBillingProjects,
  getAllBilledEmployee,
} from "../../service/employeeBilling/EmployeeBillingService";
import { TopPerformingEmp } from "../../dtos/dashboard/TopPerformingEmp";
import { TopBillingEmpDto } from "../../dtos/dashboard/TopBillingEmpDto";
import { ShowMinToHourAndMin } from "../../shared/functions/ShowMinToHourAndMin";
import { TopBillingProjChart } from "../charts/TopBillingProjChart";
import { LoggedInEmpPerformanceChart } from "../charts/LoggedInEmpPerformanceChart";
import { LoggedInUserData } from "../../shared/functions/LoggedInUserData";
import { TotalRatingsByKpiInputDto } from "../../dtos/performance/TotalRatingsByKpiInputDto";
import { TopTrackingProjChart } from "../charts/TopTrackingProjChart";
import { getSubordinatesNameIDCode } from "../../service/employee/employeeService";
import { isNotUpcomingDate } from "../../shared/functions/IsNotUpcomingDateFunc";
import DatePicker from "react-datepicker";
import { LoggedInEmpPerformanceChartForTwo } from "../charts/LoggedInEmpPerformanceChartForTwo";
import { LoggedInPerformanceData } from "../../dtos/performance/LoggedInPerformanceData";
import { EmployeePerformanceChart } from "../charts/EmployeePerformanceChart";
import { Toast } from "primereact/toast";
import { TopPerformingBillGroupProjDto } from "../../dtos/dashboard/TopPerformingBillGroupProjDto";
import { ShowSecToHourAndMin } from "../../shared/functions/ShowSecToHourAndMin";
import { TopPerformingTrackGroupProjDto } from "../../dtos/dashboard/TopPerformingTrackGroupProjDto";
import { getProfilePicFromTracker } from "../../shared/functions/GetProfilePicFromTracker";
import { Dialog } from "primereact/dialog";
import { AllBilledEmpDto } from "../../dtos/dashboard/AllBilledEmpDto";
import AllBilledEmpChart from "../charts/AllBilledEmpChart";
import { AllBilledEmpResultDto } from "../../dtos/dashboard/AllBilledEmpResultDto";
import { GetPreviousMonth } from "../../shared/functions/GetPreviousMonth";
import { getUserRole } from "../../service/user/userService";
import { getEmployeesBySkillName, getUniqueSkillWithEmpCount } from "../../service/userSkill/userSkillServices";

import {
  dashboard,
  employeePerformance,
  employeeSkills,
  topBillingEmployee,
  topBillingProjects,
  topTrackingProjects,
  yourPerformanceChart,
} from "../../shared/constant/PermissionVariables";
import UpperNavbar from "../navbars/UpperNavbar";
import { skillLogos } from "../../shared/constant/SkillImages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { ThemeState } from "../../dtos/common/ThemeState";
import { Themes } from "../../data/Themes";
import { ADMIN } from "../../shared/constant/RoleNames";

export const Dashboard = () => {
  const modeName = useSelector((state: AppModeState) => state.theme.appMode);
  const mode = AppMode.find((md) => md.name === modeName);
  const dispatch = useDispatch();
  const [date, setDate] = useState<any>(GetPreviousMonth(new Date()));
  const themeName = useSelector((state: ThemeState) => state.theme.themeName);
  const ThemeColors = Themes.find((th) => th.name === themeName);
  // const [date, setDate] = useState<any>(new Date());
  const [dateType, setDateType] = useState<string>("week");
  const [calender, setCalender] = useState<any>(
    <Calendar
      value={date}
      onChange={(e: any) => setDate(e.value)}
      style={{ backgroundColor: "red" }}
    />
  );

  const buttons = [
    { name: "Week", value: "week" },
    { name: "Month", value: "month" },
    {name:"Quarterly",value:"quarterly"},
    {name:"Half Yearly",value:"halfYearly"},
    { name: "Year", value: "year" }
  ];
  const [topPerformingEmp, setTopPerformingEmp] = useState<TopPerformingEmp[]>(
    []
  );
  const [topBillingEmp, setTopBillingEmp] = useState<TopBillingEmpDto[]>([]);
  const [allBilledEmployee, setAllBilledEmployee] = useState<
    AllBilledEmpResultDto[]
  >([]);
  const [allBillEmpTotalCount, setAllBillEmpTotalCount] = useState<number>(0);
  const [first, setFirst] = useState<number>(0);
  const [rows, setRows] = useState<number>(5);
  const [page, setPage] = useState<number>(0);

  const [loggedInEmpPerformanceData, setloggedInEmpPerformanceData] = useState<
    LoggedInPerformanceData[]
  >([]);
  const [topTrackingProjData, setTopTrackingProjData] = useState<
    TopPerformingTrackGroupProjDto[]
  >([]);
  const [topBillingProjData, setTopBillingProjData] = useState<
    TopPerformingBillGroupProjDto[]
  >([]);
  const [showOnlyUserChart, setShowOnlyUserChart] = useState<boolean>(true);
  const [disableSelectBtn, setDisableSelectBtn] = useState<boolean>(false);
  const [visible, setVisible] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [skillsWithEmpCount, setSkillsWithEmpCount] = useState<
    string | { SkillName: string; employeeCount: number }[] | []
  >([]);
 const [halfYearlyDateRange, setHalfYearlyDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const [quarterlyDateRange,setQuarterlyDateRange]=useState<[Date | null, Date | null]>([null, null]) 
  const [checkedState, setCheckedState] = useState<{ [key: string]: boolean }>({});
  const [employeeWithSkill, setEmployeeWithSkill] = useState<any[]>()

  const [selectedDate, setSelectedDate] = useState(GetPreviousMonth(new Date()));

  const [currentMonthIndex, setCurrentMonthIndex] = useState(GetPreviousMonth(new Date()).getMonth());
  const [calendarVisible, setCalendarVisible] = useState(dateType==='week'?true:false)  // for week
  const firstDay = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate() - new Date().getDay() - 6
  );
  const lastDay = new Date(
    firstDay.getFullYear(),
    firstDay.getMonth(),
    firstDay.getDate() - firstDay.getDay() + 7
  );

  const [weekDate, setWeekDate] = useState<any>([firstDay, lastDay]);
  const [monthDate, setMonthDate] = useState<any>(new Date());
 
  const [yearDate, setYearDate] = useState<any>(
    new Date(new Date().setFullYear(new Date().getFullYear()))
  );
  const localization = useSelector(
    (state: any) => state.localization.localization
  );
  const toast = useRef<any>(null);
  const userPermissions = useSelector(
    (state: any) => state.permission.permissions
  );

  const currentDate = new Date();

const startYear = currentDate.getFullYear() - 4;
const startOfRange = new Date(startYear, 0, 1); // January 1, five years ago

// Calculate the end date as December 31 of the current year
const endOfRange = new Date(currentDate.getFullYear(), 11, 31); // December 31 of the current year

const [dateRange, setDateRange] = useState([startOfRange, endOfRange]);
  const [startDate, endDate] = dateRange;  // Generate previous 10 years including the current year
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 10 }, (_, i) => currentYear - i);  //#region all Functions

  //after clicking the week button dates will set to week


  function getYearStartAndEndDates() {
    const currentDate = new Date();
    // const currentDate = new Date(2025,8,10);
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // Months are 0-indexed
    
    let yearToUse = currentMonth >= 6 ? currentYear : currentYear - 1;

    const firstDate = new Date(yearToUse, 0, 1); // January 1st
    const lastDate = new Date(yearToUse, 11, 31); // December 31st

    return {
        firstDate,
        lastDate
    };
}
const handleMonth = ()=>{
  const dates = getYearStartAndEndDates()
  setDate(dates.firstDate);
}
  const handleWeek = () => {
    const currentDate = new Date();
    const previousMonthDate = new Date(currentDate.setMonth(currentDate.getMonth() - 1));
    setDate(previousMonthDate);
    setSelectedDate(previousMonthDate)
  };
  

  //after clicking the year button dates will set to year
  const handleYear = () => {
    
    setDateRange(getPrevFiveYearFromCurrentDate()) 
  };



  const onPageChange = (event: PaginatorPageChangeEvent) => {
    setFirst(event.first);
    setRows(event.rows);
    setPage(event.page);
  };

  const getPrevFiveYearFromCurrentDate = ()=>
    {
      const currentDate = getYearStartAndEndDates().lastDate; // Current date
      const endDateYear = currentDate.getFullYear()
      const startYear = endDateYear-4
      const previousDate = new Date(startYear, 0, 1);
      const endDate = new Date(endDateYear, 11, 31);
  
      return [previousDate, endDate]; // Return as an array
    }
  

  
  const resetAllBilledEmpPaginator = () => {
    setFirst(0);
    setRows(5);
    setPage(0);
  };

  function getLastFiveYears(currentDate:Date) {
    const currentYear = currentDate.getFullYear();

    // Calculate the start date of the first year (5 years ago) and the start date of the current year
    const startYear = currentYear - 4;
    const startDate = new Date(startYear, 0, 1); // January 1st of the start year
    const endDate = new Date(currentYear, 0, 0);   // January 1st of the current year

    // Return the start and end dates in the required format
    return {
        startDate: startDate,
        endDate: endDate
    };
}





function getNextFiveYears(currentDate: Date) {
  const currentYear = currentDate.getFullYear();
  
  // Calculate the start date of the current year
  const startDate = new Date(currentYear+1, 0, 1); // January 1st of the current year

  // Calculate the end year based on currentDate and ensure it's up to the current date or the next 5 years
  const futureYear = currentDate.getFullYear() + 5;
  const maxYear = new Date().getFullYear();
  const endYear = Math.min(futureYear, maxYear); // Ensure the end year is not in the future beyond current year

  // Calculate the end date to be December 31st of the end year
  const endDate = new Date(endYear, 11, 31); // December 31st of the end year

  // Return the start and end dates in the required format
  return {
    startDate: startDate,
    endDate: endDate
  };
}

  //after clicking the month button dates will set to month


  function getLastFiveYearsRange(inputDate:Date) {
    const currentDate = inputDate // Current date
    const currentYear = currentDate.getFullYear();

    // Start date: January 1st of five years ago
    const startDate = new Date(currentYear - 4, 0, 1);

    // End date: Today's date
    const endDate = currentDate;

    return {
        startDate,
        endDate,
    };
}

  //For handling date select component buttons
  const handleDate = (e: any) => {
    let dateTypeForPicker;
    if (e.value === null) {
      dateTypeForPicker = dateType;
      setDateType((prev: any) => {
        return prev;
      });
    } else {
      setDateType(e.value);
      dateTypeForPicker = e.value;
    }

    
     if (e.value === "week") {
      // handleMonth();
      handleWeek();
    } else if (e.value === "month") {
      handleMonth();
    }else if(e.value==="year"){
      handleYear();
    }else if(e.value==="halfYearly"){
      // handleHalfYearly();
      handleMonth()
    }
  else if(e.value==="quarterly"){
      // handleQuarterly();
      handleMonth()
  }
  };

  const preventUpcomingDate = () => {
    setDate((prev: any) => prev); // Reset to the current state
    toast.current.show({
        severity: "warn",
        summary: "Sorry!",
        detail: "You can't go to the current and upcoming periods!",
        life: 3000,
    });
};


  //For previous date button
  const handlePrevDate = (type: string, date: any) => {
    if (type === "week") {
      const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      firstDay.setMonth(firstDay.getMonth() - 1);
      setDate(firstDay);
      setSelectedDate(firstDay)
      setMonthDate(firstDay);
      setCurrentMonthIndex(firstDay.getMonth());
    }
   
     else if (type === "month") {
      const prevY = new Date(date.setFullYear(date.getFullYear() - 1));
      setDate(prevY);
      setYearDate(prevY);
      
    }else if(type==="halfYearly"){
    
      const prevY = new Date(date.setFullYear(date.getFullYear() - 1));
      setDate(prevY);
      setYearDate(prevY);
  }  else if(type==="quarterly"){
   
      const prevY = new Date(date.setFullYear(date.getFullYear() - 1));
      setDate(prevY);
      setYearDate(prevY);
  }else if(type==="year"){
    const previousYearDate = getLastFiveYears(dateRange[0])
    setDateRange([previousYearDate.startDate,previousYearDate.endDate])
    setDate(previousYearDate.endDate)
  }
  };

 

  const handleNextDate = (type: string, date: any) => {
    const today = new Date(); // Current date to compare against
    
    // Helper function to handle upcoming date prevention
    const preventUpcomingDate = () => {
      toast.current.show({
        severity: "warn",
        summary: "Sorry!",
        detail: "You can't go to the current and upcoming months!",
        life: 3000,
      });
    };
  
    // General logic for advancing the year
    const getNextYearDate = () => {
      const nextY = new Date(date); // Clone the date to avoid modifying the original
      nextY.setFullYear(nextY.getFullYear() + 1);
      if (nextY > getYearStartAndEndDates().lastDate) {
        preventUpcomingDate();
        return null; // Indicate that the operation should not proceed
      }
      return nextY;
    };
  
    if (type === "week") {
      const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      firstDay.setMonth(firstDay.getMonth() + 1);
      if (
        firstDay.getFullYear() > currentDate.getFullYear() ||
        (firstDay.getFullYear() === currentDate.getFullYear() && firstDay.getMonth() >= currentDate.getMonth())
      ) {
        setDate((prev: any) => prev); // No actual update needed
        preventUpcomingDate();
        return;
      }
  
      setSelectedDate(firstDay);
      setDate(firstDay);
      setMonthDate(firstDay);
      setCurrentMonthIndex(firstDay.getMonth());
    } else if (type === "month" || type === "halfYearly" || type === "quarterly") {
      const nextY = getNextYearDate();
      if (!nextY) return; // Stop if the date is upcoming
      setDate(nextY);
      setYearDate(nextY);
    } else if (type === "year") {
      const nextYrs = getNextFiveYears(dateRange[1]);
      if ( (nextYrs.endDate.getFullYear() > getYearStartAndEndDates().lastDate.getFullYear()) || (nextYrs.startDate.getFullYear() === nextYrs.endDate.getFullYear())) {
        preventUpcomingDate();
        return;
      }
     
      setDate(nextYrs.endDate);
      setYearDate(nextYrs.endDate);
      setDateRange([nextYrs.startDate, nextYrs.endDate]);
    }
  };
  

  //This function will give first and last day of month from a selected single date
  function getFirstAndLastDatesOfMonth(inputDate: Date) {
    const year = inputDate.getFullYear();
    const month = inputDate.getMonth();
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 1);
    return {
      firstDay,
      lastDay,
    };
  }
  const getFirstAndLastDayOfWeek = (date: any) => {
    const today = new Date(date[0]); // Create a clone of the input date

    // Set the first day of the week (Monday)
    const firstDay = new Date(today);
    firstDay.setDate(
      today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1)
    );

    // Set the last day of the week (Sunday)
    const lastDay = new Date(firstDay);
    lastDay.setDate(firstDay.getDate() + 6);

    const weekDates = [firstDay, lastDay];
    setDate(weekDates); // Assuming setDate is a function you want to use to set the week dates
    setWeekDate(weekDates);
  };

  const changeDateYearly = (e:any)=>{
    if(e.getFullYear()>getYearStartAndEndDates().lastDate.getFullYear()){
      preventUpcomingDate();
      return
    }
    setDate(e)
    
  }
 const months = Array.from({ length: 12 }, (_, index) =>
    new Date(0, index).toLocaleString('default', { month: 'long' })
  );

  const changeMonth = (index: any) => {
    setCurrentMonthIndex(index);
    setSelectedDate(new Date(selectedDate.getFullYear(), index, 1));
  };


  const handlePrev = () => {
    const prevIndex = currentMonthIndex === 0 ? 11 : currentMonthIndex - 1;
    changeMonth(prevIndex);
    setSelectedDate(new Date(selectedDate.getFullYear(), prevIndex, 1));
    setDate(new Date(selectedDate.getFullYear(), prevIndex, 1))
  };

 

  const handleNext = () => {
    const nextIndex = currentMonthIndex === 11 ? 0 : currentMonthIndex + 1;
    const currentDate = new Date();
    const selectedYear = selectedDate.getFullYear();
    const selectedMonth = selectedDate.getMonth();
    
    // Check if the next month goes into the future
    if (
      (selectedYear > currentDate.getFullYear()) || 
      (selectedYear === currentDate.getFullYear() && nextIndex >= currentDate.getMonth())
    ) {
      preventUpcomingDate();
      return;
    }
  
    changeMonth(nextIndex);
    setSelectedDate(new Date(selectedDate.getFullYear(), nextIndex, 1));
    setDate(new Date(selectedDate.getFullYear(), nextIndex, 1));
  };
  


const handleDateChange = (e: any) => {
  // Get the current date and the new date's year and month
  const currentDate = new Date();
  const selectedYear = selectedDate.getFullYear();
  const selectedMonth = e.getMonth();

  // Check if the selected year and month are greater than or equal to the current year and month
  if (
    selectedYear > currentDate.getFullYear() ||
    (selectedYear === currentDate.getFullYear() && selectedMonth >= currentDate.getMonth())
  ) {
    preventUpcomingDate();
    return;
  }

  // Update the date state
  setDate(new Date(selectedYear, selectedMonth, 1));
  setSelectedDate(new Date(selectedYear, selectedMonth, 1));
  setCurrentMonthIndex(selectedMonth);
};


  const handleClose = () => { setCalendarVisible(false); } // Hide calendar when close button is clicked };

  const handleCalender = () => {
    if (dateType === "week") {
      setCalendarVisible(true)
      setCalender(calendarVisible ?

        <DatePicker
          selected={selectedDate}
          onChange={(e) => { 
            handleDateChange(e)
          }
        }
          dateFormat="MM/yyyy" // Only show month and year
          showMonthYearPicker // Restrict to month and year selection
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <div className="selectMonth"
          
            >
              <h5 className="fs-6">Select Month</h5>
              <span className="dateClose">
                <FontAwesomeIcon icon={faXmark}
                onClick={handleClose}
                />
              </span>
              
              <div className="d-flex gap-4 align-items-center justify-content-center mb-2 mt-3" >
                <div className="yearSelect">
                <select
                  className="py-1 px-1 rounded-2"
                  value={selectedDate.getFullYear()}
                  onChange={({ target: { value } }) => handleYearChange(value)}
               
                >
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
                </div>
                

                <div className="monthCont d-flex align-items-center gap-2 justify-content-center">
                  <button className="d-flex align-items-center" onClick={handlePrev} disabled={prevMonthButtonDisabled} style={{ backgroundColor: 'transparent', border: 'none', width: '12px' }}>
                    {<i className="pi pi-angle-left"></i>}
                  </button>
                  <div 
                  className="monthTxt fs-7">
                    {months[currentMonthIndex]}
                  </div>
                  <button className="d-flex align-items-center" onClick={handleNext} disabled={nextMonthButtonDisabled} style={{ backgroundColor: 'transparent', border: 'none', width: '12px' }}>
                    {<i className="pi pi-angle-right"></i>}
                  </button>
                </div>

              </div>
            </div>
          )}
        />
         : <Calendar
          value={date}
          onChange={(e: any) => setDate(e.value)}
        />

      )
      setMonthDate(selectedDate);


    }
  
    else if(dateType==="year"){
    
      setCalender(
        <DatePicker 
        selected={startDate} 
        startDate={startDate} 
        endDate={endDate} 
        selectsRange 
        customInput={<CustomInput />} 
        onChange={(update:any) => { 
          if(update[0]>getYearStartAndEndDates().lastDate || update[1]>getYearStartAndEndDates().lastDate){
            preventUpcomingDate();
            return
          }
          setDateRange(update)
          setDate(update[0])
         }} 
        dateFormat="yyyy" 
        showYearPicker />
      )
      
    }
    else if (dateType === "month") {
      setCalender(
        <DatePicker
          selected={date}
          value={date}
          customInput={<CustomInput />}
          onChange={(e) => changeDateYearly(e)}
          dateFormat="yyyy"
          showYearPicker
        />
      );
      // setYearDate(date);
    } else if (dateType === "halfYearly") {
 
  setCalender(
    <DatePicker
      selected={date}
      value={date}
      customInput={<CustomInput />}
      onChange={(e) => changeDateYearly(e)}
      dateFormat="yyyy"
      showYearPicker
    />
  );
  setYearDate(date);
    }else if (dateType === "quarterly") {
     
      setCalender(
        <DatePicker
          selected={date}
          value={date}
          customInput={<CustomInput />}
          onChange={(e) => changeDateYearly(e)}
          dateFormat="yyyy"
          showYearPicker
        />
      );
      // setYearDate(date);
    } 
  };

  const createStartAndEndDate = (input: Date[]) => {
    let dates: DashboardInputDto = {
      startDate: "",
      endDate: "",
    };
    if (date.length > 0) {
      const allStrDates = ISTToDate(input);
      dates = {
        startDate: allStrDates[0],
        endDate: allStrDates[1],
      };
    }
    //If there is only a single date and Month button is selected
    else if (date.length === undefined && dateType === "week") {
      const firstAndLastDate = getFirstAndLastDatesOfMonth(date);
      const dt = [firstAndLastDate.firstDay, firstAndLastDate.lastDay];
      const allStrDates = ISTToDate(dt);
      dates = {
        startDate: allStrDates[0],
        endDate: allStrDates[1],
      };
    } else if (dateType === "month") {
      const currentYear = date.getFullYear();
      const allStrDates = ISTToDate([
        new Date(currentYear, 0, 1),
        new Date(currentYear, 11, 31),
      ]);
      dates = {
        startDate: allStrDates[0],
        endDate: allStrDates[1],
      };
    }else if (dateType==="halfYearly"){
   
      const currentYear = date.getFullYear();
      const allStrDates = ISTToDate([
        new Date(currentYear, 0, 1),
        new Date(currentYear, 11, 31),
      ]);
      dates = {
        startDate: allStrDates[0],
        endDate: allStrDates[1],
      };
  }else if(dateType==="quarterly"){
     
      const currentYear = date.getFullYear();
      const allStrDates = ISTToDate([
        new Date(currentYear, 0, 1),
        new Date(currentYear, 11, 31),
      ]);
      dates = {
        startDate: allStrDates[0],
        endDate: allStrDates[1],
      };
  }else if(dateType==="year"){
    const firstAndLastDate = getLastFiveYearsRange(date)
    // const dt = [firstAndLastDate.startDate, firstAndLastDate.endDate];
    const dt = [dateRange[0], dateRange[1]];
    const allStrDates = ISTToDate(dt);
    dates = {
        startDate: allStrDates[0],
        endDate: allStrDates[1],
    };
  }
    return dates;
  };

  const handleTopPerformingEmp = async () => {
    const inputForPresentDate =  date;
    const isPresentDate = isNotUpcomingDate(inputForPresentDate, dateType);
    if (isPresentDate && dateType === "week") {
      let input;
      input = createStartAndEndDate(date);
      const emp = await getTopPerformedEmployee(dispatch, input);
      if (emp) {
        setTopPerformingEmp(emp);
      }
    } else {
      setTopPerformingEmp([]);
    }
  };

  const handleTopBillingEmp = async () => {
    const inputForPresentDate =  date;
    const isPresentDate = isNotUpcomingDate(inputForPresentDate, dateType);
    if (isPresentDate && dateType === "week") {
      const input = createStartAndEndDate(date);
      const emp = userPermissions.includes(topBillingEmployee)
        ? await getTopBilledEmployee(dispatch, input)
        : [];
      if (!emp) {
        setTopBillingEmp([]);
        return;
      }
      const promises = emp.map(async (e: any) => {
        const input = {
          EmployeeID: e.EmployeeID,
          EmployeeCode: e.EmployeeCode,
        };
        const image = await getProfilePicFromTracker(dispatch, input);
        e.ProfilePicture = image;
        return e;
      });

      // Wait for all the Promises to resolve
      await Promise.all(promises);
      setTopBillingEmp(emp);
    }
  };

  const handleAllBilledEmployee = async () => {
    const inputForPresentDate = date;
    const isPresentDate = isNotUpcomingDate(inputForPresentDate, dateType);
    if (isPresentDate && dateType === "week") {
      const input = createStartAndEndDate(date);
      const inputWithLimitAndPage = { ...input, limit: rows, page: page };
      const emp: AllBilledEmpDto = userPermissions.includes(dashboard)
        ? await getAllBilledEmployee(dispatch, inputWithLimitAndPage)
        : [];
      if (!emp) {
        setAllBilledEmployee([]);
        setAllBillEmpTotalCount(0);
        return;
      }
      setAllBilledEmployee(emp.result);
      setAllBillEmpTotalCount(emp.totalCount);
    }
  };

 
  


  const handleYearChange = (value :string) => {
    const year = Number(value);
  
    if (
      year > currentDate.getFullYear() ||
      (year === currentDate.getFullYear() && currentMonthIndex >= currentDate.getMonth())
    ) {
      preventUpcomingDate();
      return;
    }
    setCurrentMonthIndex(0);
    setSelectedDate(new Date(year, 0, 1));
    setDate(new Date(year,0, 1))
 
  };
  

  const handleTopPerformingProj = async () => {
    let dateTypeForTracker = 0;
    switch (dateType) {
      case "week":
        dateTypeForTracker = 5;
        break;
      case "month":
        dateTypeForTracker = 4;
        break;
      case "year":
        dateTypeForTracker = 1;
        break;
      case "halfYearly":
        dateTypeForTracker = 2;
        break;
      case "quarterly":
        dateTypeForTracker = 3;
        break;
    }
    const inputForPresentDate =  date;
    const isPresentDate = isNotUpcomingDate(inputForPresentDate, dateType);
    if (!isPresentDate) {
      setTopBillingProjData([]);
      setTopTrackingProjData([]);
      return;
    }

    const dates = createStartAndEndDate(date);
    const input = {
      dateType: dateTypeForTracker,
      startDate: dates.startDate,
      endDate: dates.endDate,
    };
    

    const topBillingProj: TopPerformingBillGroupProjDto[] =
      userPermissions.includes(topBillingProjects)
        ? await getTopBillingProjects(dispatch, input)
        : [];
    const topTrackingProj: TopPerformingTrackGroupProjDto[] =
      userPermissions.includes(topTrackingProjects)
        ? await getTopTrackingProjects(dispatch, input)
        : [];

    if (topTrackingProj?.length > 0) {
      setTopTrackingProjData(topTrackingProj);
    } else {
      setTopTrackingProjData([]);
    }

    if (topBillingProj?.length > 0) {
      setTopBillingProjData(topBillingProj);
    } else {
      setTopBillingProjData([]);
    }
  };

  const handleloggedInEmpPerformanceChart = async () => {
    const inputForPresentDate = dateType === "week" ? date : date;
    const isPresentDate = isNotUpcomingDate(inputForPresentDate, dateType);

    if (isPresentDate && dateType == "week") {
      const loggedInUser = await LoggedInUserData(dispatch);
      let dates;
      dates = createStartAndEndDate(date);
    
      const input: TotalRatingsByKpiInputDto = {
        empCode: loggedInUser?.EmployeeCode,
        startDate: dates?.startDate,
        endDate: dates?.endDate,
      };

      if (date) {
        const res: LoggedInPerformanceData[] =
          await getRatingsByKpiOfLoggedInUser(dispatch, input);
        if (res) {
          if (Object?.keys(res)?.length > 0) {
            setloggedInEmpPerformanceData(res);
          } else {
            setloggedInEmpPerformanceData([]);
          }
        }
      }
    }
  };

  const getAllSubordinates = async () => {
    const res = await getSubordinatesNameIDCode(dispatch);
    if (res?.length <= 1) {
      setShowOnlyUserChart(false);
    }
  };
  const getSkillsWithEmpCount = async () => {
    const res = await getUniqueSkillWithEmpCount(dispatch);
    setSkillsWithEmpCount(res);
  };
  const handleAdminChart = async () => {
    const role = await getUserRole(dispatch);
    if (role === ADMIN) {
      setIsAdmin(true);
    }
  };

  const viewAllBilledEmployee = () => {
    setVisible(true);
    handleAllBilledEmployee();
  };

  // Handle checkbox change
  const handleEmpSkillOnLoad = async (skillName: string, isChecked: boolean) => {
    if (isChecked) {
      const allEmployeesBySkillName = await getEmployeesBySkillName(dispatch, { skillName })
      setEmployeeWithSkill(allEmployeesBySkillName)
    }
  }

  const handleCheckboxChange = async (skillName: string, isChecked: boolean) => {
    if (isChecked) {
      const allEmployeesBySkillName = await getEmployeesBySkillName(dispatch, { skillName })
      setEmployeeWithSkill(allEmployeesBySkillName)
    }

    setCheckedState((prevState: any) => ({
      // ...prevState,
      [skillName]: !prevState[skillName], // Toggle the current checkbox state
    }));
  };

  useEffect(() => {
    // Ensure skillsWithEmpCount is a non-empty array
    if (Array.isArray(skillsWithEmpCount) && skillsWithEmpCount.length > 0) {
      // Check if the first element has SkillName property
      const firstSkill = skillsWithEmpCount[0];

      if (typeof firstSkill === "object" && firstSkill !== null && "SkillName" in firstSkill) {
        // Access SkillName safely

        handleEmpSkillOnLoad(firstSkill.SkillName, true)
        setCheckedState({ [(firstSkill as { SkillName: string }).SkillName]: true });
      }
    }

  }, [skillsWithEmpCount]);




  useEffect(() => {
    // Ensure skillsWithEmpCount is a non-empty array
    if (Array.isArray(skillsWithEmpCount) && skillsWithEmpCount.length > 0) {
      // Check if the first element has SkillName property
      const firstSkill = skillsWithEmpCount[0];

      if (
        typeof firstSkill === "object" &&
        firstSkill !== null &&
        "SkillName" in firstSkill
      ) {
        // Access SkillName safely

        handleEmpSkillOnLoad(firstSkill.SkillName, true);
        setCheckedState({
          [(firstSkill as { SkillName: string }).SkillName]: true,
        });
      }
    }
  }, [skillsWithEmpCount]);

  //#endregion

  //#region all Templates
  const CustomInput = forwardRef(({ value, onClick }: any, ref: any) => (
    <div className="example-custom-input" onClick={onClick} ref={ref}>
      {value}
    </div>
  ));
  const startToolbar = () => {
    return (
      <div className="report-start-tool ">
        <div className="selectBtn">
          <SelectButton
            value={dateType}
            onChange={(e) => handleDate(e)}
            optionLabel="name"
            options={buttons}
            disabled={disableSelectBtn}
          />
        </div>
      </div>
    );
  };
  const endToolbar = () => {
    return (
      <div className="report-end-tool">
        {/* visible input calender */}

        <div className="date-input border_primary flex-fill rounded-3">
          <Button
            icon={<AiOutlineLeft />}
            onClick={() => handlePrevDate(dateType, date)}
            className="date-comp-prev-btn"
            disabled={disableSelectBtn}
          />
          {calender}

          <Button
            icon={<AiOutlineRight />}
            onClick={() => handleNextDate(dateType, date)}
            className="date-comp-next-btn"
            disabled={disableSelectBtn}
          />
        </div>
      </div>
    );
  };
  //#endregion

  //#region all UseEffects

  useEffect(() => {
    handleAllBilledEmployee();
  }, [first, rows]);

  useEffect(() => {
    if (dateType === "month") {
      setCalendarVisible(true)
    }
  }, [calender])

  const handleAllFunc = async () => {
    setDisableSelectBtn(true);
    await Promise.all([
      handleloggedInEmpPerformanceChart(),
      userPermissions.includes(topBillingEmployee) && handleTopBillingEmp(),
      handleTopPerformingEmp(),
      handleTopPerformingProj(),
    ]);
    setDisableSelectBtn(false);
  };

  useEffect(() => {
    
    handleCalender();
    handleAllFunc();
    handleAdminChart();
    
  }, [
    dateType,
    date,
    mode?.color,
    showOnlyUserChart,
  dateRange,
  selectedDate,
    window.location.pathname,
    calendarVisible
  ]);

  useEffect(() => {
    getAllSubordinates();
  }, []);

  useEffect(() => {
    getSkillsWithEmpCount();
  }, []);

  //#endregion
  return (
    <div>
      <Toast ref={toast} />

      <div className="dashboardFilter_sec mb-3">
        <Toolbar
          start={startToolbar}
          end={endToolbar}
          className="border-0 bg-transparent p-0"
        />
      </div>

      <div className="main-dashboard row">
        {/* TOP BILLING EMPLOYEE ******************/}
        {dateType === "week" &&
          userPermissions.includes(topBillingEmployee) && (
            <div className="col-lg-4 mb-3 pr-sm-1">
              <Card
                style={{
                  backgroundColor: mode?.backgroundSecondaryColor,
                  color: mode?.color,
                }}
                className="top-billing-emp border"
              >
                <div
                  className="p-card-title dashboard-card-title"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h3 className="dashboard-card-head">
                    {localization?.TopBillingEmployee ||
                      "Top Billing Employee "}
                  </h3>
                  <span
                    className="viewAllBtn text-primary"
                    onClick={viewAllBilledEmployee}
                  >
                    View All
                  </span>
                </div>
                <Dialog
                  header="All Billed Employees"
                  visible={visible}
                  // style={{ width: "50vw" }}
                  style={{
                    backgroundColor: mode?.backgroundSecondaryColor,
                    color: mode?.color,
                    width: "50vw",
                  }}
                  contentStyle={{
                    backgroundColor: mode?.backgroundSecondaryColor,
                    color: mode?.color,
                  }}
                  headerStyle={{
                    backgroundColor: mode?.backgroundSecondaryColor,
                    color: mode?.color,
                  }}
                  onHide={() => {
                    setVisible(false);
                    resetAllBilledEmpPaginator();
                  }}
                >
                  <p className="m-0">
                    {allBilledEmployee?.length > 0 ? (
                      <div>
                        <AllBilledEmpChart
                          data={allBilledEmployee}
                          mode={mode}
                        />
                        <Paginator
                          first={first}
                          rows={rows}
                          totalRecords={allBillEmpTotalCount}
                          rowsPerPageOptions={[5, 10, 15]}
                          onPageChange={onPageChange}
                          style={{
                            color: mode?.color,
                          }}
                        />
                      </div>
                    ) : (
                      <div style={{ color: mode?.color }} className="no-data">
                        No data to show
                      </div>
                    )}
                  </p>
                </Dialog>
                <div className="dashbord-card-cont top_performing_empCont p-0">
                  {topBillingEmp.length > 0 ? (
                    <div>
                      {topBillingEmp?.map((emp) => (
                        <div
                          key={emp?.EmployeeID}
                          // className="top-performing-emp-box p-3 border-bottom"
                          className="top-performing-emp-box d-flex align-items-start px-3 py-2 border-bottom"
                        >
                          <div className="employees-picture me-3">
                            <img
                              src={
                                emp?.ProfilePicture ||
                                "https://i.pinimg.com/736x/64/81/22/6481225432795d8cdf48f0f85800cf66.jpg"
                              }
                              alt="https://i.pinimg.com/736x/64/81/22/6481225432795d8cdf48f0f85800cf66.jpg"
                              onError={(event: any) =>
                              (event.target.src =
                                "https://i.pinimg.com/736x/64/81/22/6481225432795d8cdf48f0f85800cf66.jpg")
                              }
                            />
                          </div>
                          <div className="dashboard-emp">
                            <p className="fw-bold">
                              {/* <span className="head-text">Name:</span>{" "} */}
                              {emp?.EmployeeName}
                            </p>
                            <p className="fw-light fs-8">
                              <span className="head-text fw-light">Code: </span>{" "}
                              {emp?.EmployeeCode}
                            </p>
                            <p className="fw-light">
                              <span className="head-text fw-light">Billing Time: </span>{" "}
                              {ShowMinToHourAndMin(
                                "",
                                emp?.BillingTimeInMinute
                              )}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div style={{ color: mode?.color }} className="no-data">
                      No data to show
                    </div>
                  )}
                </div>
              </Card>
            </div>
          )}
        {/* TOP SKILLED EMPLOYEE ******************/}
        {/* {dateType !== "week" && (
          <div className="col-lg-4 mb-3 pr-1">
            <Card
              style={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
              className="top-billing-emp"
            >
              <div
                className="p-card-title dashboard-card-title"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <h3 className="dashboard-card-head">
                  {localization?.TopBillingEmployee || "Top Skilled Employee "}
                </h3>
                <span
                  className="viewAllBtn"
                  style={{
                    color: mode?.name === "light" ? "blue" : "white"
                  }}
                  onClick={viewAllSkilledEmployee}
                >
                  View All
                </span>
              </div>
              <Dialog
                header="All Skilled Employees"
                visible={showSkilledEmpDialog}
                // style={{ width: "50vw" }}
                style={{
                  backgroundColor: mode?.backgroundSecondaryColor,
                  color: mode?.color,
                  width: "50vw"
                }}
                contentStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }}
                headerStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }}
                onHide={() => {setShowSkilledEmpDialog(false);resetSkillDialogPaginator()}}
              >
                <p className="m-0">
                  {topSkilledEmp.length > 0 ? (
                    <div>
                      
                      <AllSkilledEmpChart data={topSkilledEmp} mode={mode} />
                      <Paginator
                        first={skillFirst}
                        rows={skillRows}
                        totalRecords={topSkilledEmpTotalCount}
                        rowsPerPageOptions={[5, 10, 15]}
                        onPageChange={onSkillDialogPageChange}
                        style={{
                          color: mode?.color,
                        }}
                      />
                    </div>
                  ) : (
                    <div style={{ color: mode?.color }} className="no-data">
                      No data to show
                    </div>
                  )}
                </p>
              </Dialog>
              <div className="dashbord-card-cont">
                {topSkilledEmp.length > 0 ? (
                  <div>
                    {allSkilledEmp?.slice(0,5).map((emp:any) => (
                      <div
                        key={emp?.employeeCode}
                        className="top-performing-emp-box"
                      >
                        <div className="employees-picture">
                          <img
                            src={
                              emp?.ProfilePicture ||
                              "https://i.pinimg.com/736x/64/81/22/6481225432795d8cdf48f0f85800cf66.jpg"
                            }
                            alt="https://i.pinimg.com/736x/64/81/22/6481225432795d8cdf48f0f85800cf66.jpg"
                            onError={(event: any) =>
                            (event.target.src =
                              "https://i.pinimg.com/736x/64/81/22/6481225432795d8cdf48f0f85800cf66.jpg")
                            }
                          />
                        </div>
                        <div className="dashboard-emp">
                          <p>
                            <span className="head-text">Name:</span>{" "}
                            {emp?.fullName}
                          </p>
                          <p>
                            <span className="head-text">Code: </span>{" "}
                            {emp?.employeeCode}
                          </p>
                          <p>
                            <span className="head-text">Skill Count: </span>{" "}
                            { emp?.skills.length}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div style={{ color: mode?.color }} className="no-data">
                    No data to show
                  </div>
                )}
              </div>
            </Card>
          </div>
        )} */}
        {/*******************/}

     
{userPermissions.includes(yourPerformanceChart) && dateType === "week" && (
          <div className={isAdmin ? "col-md-12 mb-3" : "col-md-8 mb-3"}>
            <Card
              style={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
              className="userPerformanceChart border_primary"
            >
              <div className="p-card-title dashboard-card-title">
                <h3 className="dashboard-card-head">
                  {localization?.YourPerformanceChart ||
                    "Your Performance Chart"}
                </h3>
              </div>
              <div className="dashbord-card-cont PerformanceChart">
                {loggedInEmpPerformanceData?.length <= 2 &&
                loggedInEmpPerformanceData?.length > 0 ? (
                  <LoggedInEmpPerformanceChartForTwo
                    Data={loggedInEmpPerformanceData}
                  />
                ) : loggedInEmpPerformanceData?.length > 2 ? (
                  <LoggedInEmpPerformanceChart
                    Data={loggedInEmpPerformanceData}
                  />
                ) : (
                  <div style={{ color: mode?.color }} className="no-data">
                    No data to show
                  </div>
                )}
              </div>
            </Card>
          </div>
        )}

            
      

        {/* Employee performance chart ****************/}
        {dateType === "week" &&
          userPermissions.includes(employeePerformance) && (
            <div className={isAdmin ? "col-lg-8 mb-3" : "col-md-12 mb-3"}>
              <Card
                style={{
                  backgroundColor: mode?.backgroundSecondaryColor,
                  color: mode?.color,
                }}
                className="top_billing_emp border_primary cs_card_shadow"
              >
                <div className="p-card-title dashboard-card-title">
                  <h3 className="dashboard-card-head">
                    {localization?.EmployeePerformance ||
                      "Employee Performance"}
                  </h3>
                </div>

                <div className="dashbord-card-cont">
                  {topPerformingEmp.length > 0 ? (
                    <EmployeePerformanceChart Data={topPerformingEmp} formatInitials={formatInitials} />
                  ) : (
                    <div style={{ color: mode?.color }} className="no-data">
                      No data to show
                    </div>
                  )}
                </div>
              </Card>
            </div>
          )}
        {/* Skills ******************/}
        <div className="col-md-12 mb-3">
          {userPermissions.includes(employeeSkills) && (
            <div className="employeeSkillCont row">
              <div className="col-lg-4 pr-sm-1 ">
                <Card
                  className="border_primary cs_card_shadow p-0"
                  style={{
                    backgroundColor: mode?.backgroundSecondaryColor,
                    color: mode?.color,
                  }}
                >
                  <h3 className="dashboard-card-head pt-3 mb-2">
                    {localization?.EmployeeSkills || "Employee Skills"}
                  </h3>

                  <div
                    // className="skill-badge-container pt-3 overflow-scroll"
                    className={`skill-badge-container py-3 ${skillsWithEmpCount?.length > 3 ? "overflow-auto custom-scroll" : ""
                      }`}
                    style={{
                      // maxHeight: skillsWithEmpCount.length > 3 ? "300px" : "auto",
                      paddingRight: "10px", // Space between content and scrollbar
                      paddingLeft: "3px",
                    }}
                  >
                    {typeof skillsWithEmpCount !== "string"
                      ? skillsWithEmpCount.map((skill, i) => {
                        const logoUrl =
                          skillLogos[skill.SkillName.toLowerCase()] || skillLogos.default;
                        return (


                          <div style={{cursor:'pointer'}}
                          onClick={(e) => {
                            // Ignore clicks on specific child elements
                            const clickedElement = e.target as HTMLElement;
                            if (clickedElement.tagName === "INPUT") return; // Ignore checkbox clicks
                            handleCheckboxChange(skill.SkillName, true);
                          }}
                        
                           className={`${checkedState[skill.SkillName] ? "listActiveBg" : ""} employeeSkillSingle d-flex align-items-center justify-content-between mb-3`}>
                            <div className="d-flex gap-2 justify-content-start"  >
                              <div className="text-base font-bold p-avatar p-component rounded-4 p-avatar-lg" >
                                {/* <span className="p-avatar-text">BS</span> */}
                                <img
                                  src={logoUrl}
                                  alt={`${skill.SkillName} logo`}
                                  className="p-avatar-img rounded-4"
                                />
                              </div>
                              <div className="ml-3">
                                <span className="block text-capitalize" style={{ color: `${modeName === "dark" ? "white" : "black"}` }}>{skill.SkillName}</span>
                                <span className="text-blue-400 block">Software</span>
                              </div>
                            </div>

                            <div className="d-flex gap-3 align-items-center">
                              <span className="text-danger">({skill.employeeCount})</span>
                              <div className="cx_checkbox"
                               onChange={(e: any) =>{
                                e.stopPropagation(); // Stop the click event from bubbling to the parent `div`
                                handleCheckboxChange(skill.SkillName, e.target.checked)
                              }}// Pass skill name to the handler
                              >
                                <input type="checkbox"
                                  style={{cursor:'pointer'}}
                                  checked={!!checkedState[skill.SkillName]} // Check individual state
                                 
                                  className={checkedState[skill.SkillName] ? "checkboxActive" : ""}
                                />
                              </div>

                
                            </div>
                          </div>

                        );
                      })
                      : "No Skills To Show"}
                  </div>
                </Card>
              </div>
              <div className="col-lg-8">
                <div className="TPE_lightbg h-100 p-4 rounded-3">
                  <div className="row">
                    {employeeWithSkill?.map((empInfo) => {
                      return <div className="col-md-4">
                        <div className="skillEmployeeSngl d-flex gap-2 justify-content-start cs_card_shadow mb-2">
                          <div className="lightBg text-blue-400 font-bold p-avatar p-component rounded-3 p-avatar-lg">
                            <span className="p-avatar-text">{formatInitials(empInfo.name)}</span>
                          </div>
                          <div className="ml-2">
                            <span className="block text-capitalize text-base line-height-2">{empInfo.name}</span>
                            <span className="block text-capitalize text-blue-400">{empInfo.role[0]}</span>
                          </div>
                        </div>
                      </div>
                    })

                    }
                  </div>
                </div>
              </div>
            </div>

          )}
        </div>
        {/*******************/}
        {/* TOP Billing PROJECTS ******************/}
        {userPermissions.includes(topBillingProjects) && (
          <div className="col-md-6 mb-3 pr-sm-1">
            <Card
              style={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
              className="top-billing-proj border_primary cs_card_shadow"
            >
              <div className="p-card-title">
                <h3 className="dashboard-card-head">
                  {localization?.TopBillingProjects || "Top Billing Projects"}
                </h3>
              </div>
              <div className="dashbord-card-cont">
                {topBillingProjData?.length > 0 ? (
                  <TopBillingProjChart
                    data={topBillingProjData}
                    dateType={dateType}
                    date={dateType ==='year'?dateRange:date}
                    // date={date}
                  />
                ) : (
                  <div style={{ color: mode?.color }} className="no-data">
                    No data to show
                  </div>
                )}
                {topBillingProjData?.length > 0 && (
                  <div className="total-times TPE_lightbg p-3">
                    {topBillingProjData?.map((val, i) => (
                      <ul key={i}>
                        <li>
                          {val.projectName} :{" "}
                          {ShowSecToHourAndMin(val.totalBill as number)}
                        </li>
                      </ul>
                    ))}
                  </div>
                )}
              </div>
            </Card>
          </div>
        )}

        {/* TOP Tracking PROJECTS ******************/}
        {userPermissions.includes(topTrackingProjects) && (
          <div className="col-md-6 mb-3">
            <Card
              style={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
              className="top-tracking-proj border_primary cs_card_shadow"
            >
              <div className="p-card-title">
                <h3 className="dashboard-card-head">
                  {localization?.TopTrackingProjects || "Top Tracking Projects"}
                </h3>
              </div>
              <div className="dashbord-card-cont">
                {topTrackingProjData?.length > 0 ? (
                  <TopTrackingProjChart
                    data={topTrackingProjData}
                    dateType={dateType}
                    date={dateType ==='year'?dateRange:date}
                  />
                ) : (
                  <div style={{ color: mode?.color }} className="no-data">
                    No data to show
                  </div>
                )}
                {topTrackingProjData?.length > 0 && (
                  <div className="total-times TPE_lightbg p-3">
                    {topTrackingProjData?.map((val, i) => (
                      <ul key={i}>
                        <li>
                          {val.projectName} :{" "}
                          {ShowSecToHourAndMin(val?.totalTrack as number)}
                        </li>
                      </ul>
                    ))}
                  </div>
                )}
              </div>
            </Card>
          </div>
        )}
      </div>
    </div>
  );
};
