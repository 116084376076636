import { Toolbar } from "primereact/toolbar";
import { useDispatch, useSelector } from "react-redux";
import { AppMode } from "../../data/AppMode";
import { AppModeState } from "../../dtos/common/AppModeState";
import { SelectButton } from "primereact/selectbutton";
import { Button } from "primereact/button";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { forwardRef, useEffect, useRef, useState } from "react";
import { Calendar } from "primereact/calendar";
import { isNotUpcomingDate } from "../../shared/functions/IsNotUpcomingDateFunc";
import DatePicker from "react-datepicker";
import { Card } from "primereact/card";
import { getSubordinatesNameIDCode } from "../../service/employee/employeeService";
import { MultiSelect } from "primereact/multiselect";
import {
  getMonthlyRatingsWithKPI,
  getPerformanceReport,
  getPerformanceReportByKPI,
} from "../../service/performance/PerformanceService";
import { PerformanceReportInputDto } from "../../dtos/performance/PerformanceReportInputDto";
import { ISTToDate } from "../../shared/functions/ISTToDate";
import { Column } from "primereact/column";
import { DashboardInputDto } from "../../dtos/dashboard/DashboardInputDto";
import "../../css/components/performanceReport.css";
import { DataTable } from "primereact/datatable";
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PerformanceMonthlyReportDto } from "../../dtos/performance/PerformanceMonthlyReportDto";
import { PerformanceYearlyReportDto } from "../../dtos/performance/PerformanceYearlyReportDto";
import { ProgressSpinner } from "primereact/progressspinner";
import { SiMicrosoftexcel } from "react-icons/si";
import { EmpPerformanceReportMonthlyChart } from "../charts/EmpPerformanceReportMonthlyChart";
import { EmpPerformanceReportYearlyChart } from "../charts/EmpPerformanceReportYearlyChart";
import instance from "../../utills/BaseService";
import { MonthlyRatingsWithKPI } from "../../dtos/performance/MonthlyRatingsWithKPI";
import { Themes } from "../../data/Themes";
import { ThemeState } from "../../dtos/common/ThemeState";
import { Toast } from "primereact/toast";
import { GetPreviousMonth } from "../../shared/functions/GetPreviousMonth";

export const PerformanceReport = () => {
  const modeName = useSelector((state: AppModeState) => state.theme.appMode);
  const mode = AppMode.find((md) => md.name === modeName);
  const dispatch = useDispatch();
  //get previous month
  const [date, setDate] = useState<any>(GetPreviousMonth(new Date()));
  const [dateType, setDateType] = useState<string>("month");
  const [calender, setCalender] = useState(
    <Calendar
      value={date}
      onChange={(e) => setDate(e.value)}
      style={{ backgroundColor: "red" }}
    />
  );
  const [disableSelectBtn, setDisableSelectBtn] = useState<boolean>(false);
  const [monthDate, setMonthDate] = useState<any>(new Date());
  const [yearDate, setYearDate] = useState<any>(
    new Date(new Date().setFullYear(new Date().getFullYear()))
  );
  const toast = useRef<any>(null);
  const [employee, setEmployee] = useState<any[]>([]);
  const [selectedEmployee, setSelectedEmployee] = useState<string[] | null>(
    null
  );
  const localization = useSelector(
    (state: any) => state.localization.localization
  );
  const [reportData, setReportData] = useState<
    PerformanceMonthlyReportDto[] | PerformanceYearlyReportDto[]
  >([]);
  const [expandedRow, setExpandedRow] = useState<string>("");
  const [historyOfReportData, setHistoryOfReportData] = useState<
    PerformanceMonthlyReportDto[]
  >([]);
  const [excelBtn, setExcelBtn] = useState(true);
  const [monthlyChartData, setMonthlyChartData] = useState<
    MonthlyRatingsWithKPI[]
  >([]);
  const [excelLoader, setExcelLoader] = useState(false);
  const themeName = useSelector((state: ThemeState) => state.theme.themeName);
  const [halfYearlyDateRange, setHalfYearlyDateRange] = useState<
    [Date | null, Date | null]
  >([null, null]);
  const [quarterlyDateRange, setQuarterlyDateRange] = useState<
    [Date | null, Date | null]
  >([null, null]);
  const [financialYearDateRange, setFinancialYearDateRange] = useState<
    [Date | null, Date | null]
  >([null, null]);
  const ThemeColors = Themes.find((th) => th.name === themeName);
  const buttons = [
    { name: "Month", value: "month" },
    { name: "Year", value: "year" },
    { name: "Half Yearly", value: "halfYearly" },
    { name: "Quarterly", value: "quarterly" },
    { name: "Financial Year", value: "financialYear" },
  ];

  //#region all Functions

  function getYearStartAndEndDates() {
    // const currentDate = new Date();
    const currentDate = new Date(2026, 0, 10); // Example for January

    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // Months are 0-indexed

    let yearToUse = currentMonth === 1 ? currentYear - 1 : currentYear;

    const firstDate = new Date(yearToUse, 0, 1); // January 1st
    const lastDate = new Date(yearToUse, 11, 31); // December 31st

    return {
        firstDate,
        lastDate
    };
}



function getFinancialYearStartAndEndDates() {
  const currentDate = new Date();
  // const currentDate = new Date(2025, 6, 10); // Example for April

  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  let yearToUse: number;

  // If the current month is May or later, use the current financial year
  // If the current month is April, use the previous financial year
  if (currentMonth >= 4) {
    yearToUse = currentYear;
  } else {
    yearToUse = currentYear - 1;
  }

  const financialYearStart = new Date(yearToUse, 3, 1); // April 1st
  const financialYearEnd = new Date(yearToUse + 1, 2, 31); // March 31st of next year

  return {
    firstDate: financialYearStart,
    lastDate: financialYearEnd,
  };
}




  const getAllEmployees = async () => {
    const employees = await getSubordinatesNameIDCode(dispatch);
    setEmployee(employees);
  };

  //This function will give first and last day of month from a selected single date
  function getFirstAndLastDatesOfMonth(inputDate: Date) {
    const year = inputDate.getFullYear();
    const month = inputDate.getMonth();
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    return {
      firstDay,
      lastDay,
    };
  }

  function getHalfYearDates(inputDate: Date) {
    const year = inputDate.getFullYear();

    // Determine which half of the year the date falls into
    const isFirstHalf = inputDate.getMonth() < 6; // Months 0–5 are Jan–Jun (first half)

    // Calculate start and end dates of the half-year
    const startDate = isFirstHalf ? new Date(year, 0, 1) : new Date(year, 6, 1); // Jan 1 or Jul 1
    const endDate = isFirstHalf ? new Date(year, 6, 0) : new Date(year, 12, 0); // Jun 30 or Dec 31

    return {
      startDate,
      endDate,
    };
  }


  function getFinancialYearDates(inputDate: Date) {
    const year = inputDate.getFullYear();

    // Determine if the date falls before or after April 1st
    const isBeforeApril = inputDate.getMonth() < 3; // Months 0–2 are Jan–Mar

    // Calculate start and end dates of the financial year
    const startDate = isBeforeApril
      ? new Date(year - 1, 3, 1) // April 1st of previous year
      : new Date(year, 3, 1);    // April 1st of current year

    const endDate = isBeforeApril
      ? new Date(year, 2, 31)    // March 31st of current year
      : new Date(year + 1, 2, 31); // March 31st of next year

    return {
      startDate,
      endDate,
    };
  }


  const moveToPreviousFinancialYear = () => {
    setFinancialYearDateRange((prevRange) => {
      const [startDate, endDate] = prevRange;

      if (startDate && endDate) {
        const previousStartDate = new Date(startDate);
        previousStartDate.setFullYear(startDate.getFullYear() - 1);

        const previousEndDate = new Date(endDate);
        previousEndDate.setFullYear(endDate.getFullYear() - 1);

        return [previousStartDate, previousEndDate];
      }

      // Default fallback if dates are null
      return [null, null];
    });
  };


  function getPreviousHalfYearDates(inputDate: Date) {
    // Normalize the input date to remove the time component
    const normalizedDate = new Date(
      inputDate.getFullYear(),
      inputDate.getMonth(),
      inputDate.getDate()
    );

    const year = normalizedDate.getFullYear();
    const month = normalizedDate.getMonth(); // 0-based index for months in JavaScript

    // Determine if the input date is in the first half of the year
    const isFirstHalf = month < 6; // Months 0–5 are Jan–Jun (first half)

    // Calculate the previous half-year's start and end dates
    const prevHalfStartDate = isFirstHalf
      ? new Date(year - 1, 6, 1) // Start of the second half of the previous year
      : new Date(year, 0, 1); // Start of the first half of the current year

    const prevHalfEndDate = isFirstHalf
      ? new Date(year - 1, 12, 0) // End of the second half of the previous year (Dec 31)
      : new Date(year, 6, 0); // End of the first half of the current year (Jun 30)

    return {
      startDate: prevHalfStartDate,
      endDate: prevHalfEndDate,
    };
  }


  function getQuarterlyDates(inputDate: Date) {
    const year = inputDate.getFullYear();
    const month = inputDate.getMonth();

    // Determine the quarter based on the month
    let startMonth, endMonth;
    if (month < 3) {
      // Q1: January (0) to March (2)
      startMonth = 0;
      endMonth = 2;
    } else if (month < 6) {
      // Q2: April (3) to June (5)
      startMonth = 3;
      endMonth = 5;
    } else if (month < 9) {
      // Q3: July (6) to September (8)
      startMonth = 6;
      endMonth = 8;
    } else {
      // Q4: October (9) to December (11)
      startMonth = 9;
      endMonth = 11;
    }

    // Calculate the start and end dates of the quarter
    const startDate = new Date(year, startMonth, 1);
    const endDate = new Date(year, endMonth + 1, 0); // 0th day of the next month

    return {
      startDate,
      endDate,
    };
  }

  function getPreviousQuarterDates(inputDate: Date) {
    // const year = inputDate.getFullYear();
    // const month = inputDate.getMonth();

    // Normalize the input date to remove the time component
    const normalizedDate = new Date(
      inputDate.getFullYear(),
      inputDate.getMonth(),
      inputDate.getDate()
    );

    const year = normalizedDate.getFullYear();
    const month = normalizedDate.getMonth(); // 0-based index for months in JavaScript

    // Determine the current quarter based on the month
    let startMonth, endMonth;

    if (month < 3) {
      // Q1: January (0) to March (2)
      startMonth = 9; // Previous quarter (Q4)
      endMonth = 11; // Previous quarter (Q4)
    } else if (month < 6) {
      // Q2: April (3) to June (5)
      startMonth = 0; // Previous quarter (Q1)
      endMonth = 2; // Previous quarter (Q1)
    } else if (month < 9) {
      // Q3: July (6) to September (8)
      startMonth = 3; // Previous quarter (Q2)
      endMonth = 5; // Previous quarter (Q2)
    } else {
      // Q4: October (9) to December (11)
      startMonth = 6; // Previous quarter (Q3)
      endMonth = 8; // Previous quarter (Q3)
    }

    // Adjust the year if necessary for the previous quarter
    const prevQuarterStartDate = new Date(
      month < 3 ? year - 1 : year,
      startMonth,
      1
    );
    const prevQuarterEndDate = new Date(
      month < 3 ? year - 1 : year,
      endMonth + 1,
      0
    ); // 0th day of the next month

    return {
      startDate: prevQuarterStartDate,
      endDate: prevQuarterEndDate,
    };
  }

  function getNextQuarterDates(inputDate: Date) {
    const year = inputDate.getFullYear();
    const month = inputDate.getMonth();

    // Determine the current quarter based on the month
    let startMonth, endMonth;

    if (month < 3) {
      // Q1: January (0) to March (2)
      startMonth = 3; // Next quarter (Q2)
      endMonth = 5; // Next quarter (Q2)
    } else if (month < 6) {
      // Q2: April (3) to June (5)
      startMonth = 6; // Next quarter (Q3)
      endMonth = 8; // Next quarter (Q3)
    } else if (month < 9) {
      // Q3: July (6) to September (8)
      startMonth = 9; // Next quarter (Q4)
      endMonth = 11; // Next quarter (Q4)
    } else {
      // Q4: October (9) to December (11)
      startMonth = 0; // Next quarter (Q1 of the next year)
      endMonth = 2; // Next quarter (Q1 of the next year)
    }

    // Adjust the year if necessary for the next quarter
    const nextQuarterStartDate = new Date(
      month > 8 ? year + 1 : year,
      startMonth,
      1
    );
    const nextQuarterEndDate = new Date(
      month > 8 ? year + 1 : year,
      endMonth + 1,
      0
    ); // 0th day of the next month

    return {
      startDate: nextQuarterStartDate,
      endDate: nextQuarterEndDate,
    };
  }

  const createEmpAndProjArr = (arr: any[]) => {
    const data: string[] = [];
    arr?.forEach((e: any) => {
      data.push(e?.employeeCode?.toString());
    });
    return data;
  };

  const createStartAndEndDate = (input: Date[]) => {
    let dates: DashboardInputDto = {
      startDate: "",
      endDate: "",
    };
    if (date?.length > 0) {
      const allStrDates = ISTToDate(input);
      dates = {
        startDate: allStrDates[0],
        endDate: allStrDates[1],
      };
    }
    //If there is only a single date and Month button is selected
    else if (date?.length === undefined && dateType === "month") {
      const firstAndLastDate = getFirstAndLastDatesOfMonth(date);
      const dt = [firstAndLastDate.firstDay, firstAndLastDate.lastDay];
      const allStrDates = ISTToDate(dt);
      dates = {
        startDate: allStrDates[0],
        endDate: allStrDates[1],
      };
    } else if (dateType === "year" || dateType === "halfYearly" || dateType === "quarterly") {
      const currentYear = date.getFullYear();
      const allStrDates = ISTToDate([
        new Date(currentYear, 0, 1),
        new Date(currentYear, 11, 31),
      ]);
      dates = {
        startDate: allStrDates[0],
        endDate: allStrDates[1],
      };
    } else if (dateType === 'financialYear') {
      const financialDateRange = getFinancialYearDates(financialYearDateRange[0] as Date)
      const allStrDates = ISTToDate([
        financialDateRange.startDate,
        financialDateRange.endDate
      ]);
      dates = {
        startDate: allStrDates[0],
        endDate: allStrDates[1],
      };
    }

    return dates;
  };

  const getAllReport = async () => {
    setDisableSelectBtn(true);
    setExcelBtn(true);
    const input: PerformanceReportInputDto = {
      // dateType: dateType === "month" ? "0" : "1",
      dateType: getDateType(dateType),
      startDate: createStartAndEndDate(date).startDate,
      endDate: createStartAndEndDate(date).endDate,
      employeeCodes: createEmpAndProjArr(selectedEmployee as string[]),
    };

    if (input.employeeCodes.length === 0) {
      setReportData([]);
      setDisableSelectBtn(false);
      return;
    }

    const res: PerformanceMonthlyReportDto[] = await getPerformanceReport(
      dispatch,
      input
    );

    res.forEach((data) => (data.isKPIPresent = false));

    //for chart
    input.dateType = "0";
    const monthlyKPIRating: MonthlyRatingsWithKPI[] =
      await getMonthlyRatingsWithKPI(dispatch, input);

    if (monthlyKPIRating.length > 0) {
      setMonthlyChartData(monthlyKPIRating);
    }

    setReportData(res);
    setHistoryOfReportData(res);
    if (res.length > 0) {
      setExcelBtn(false);
    }
    setDisableSelectBtn(false);
  };

  const employeeOptions = employee.map((emp) => ({
    label: `${emp.fullName} (${emp.employeeCode})`,
    value: emp, // You can set the entire employee object as the value if needed
  }));

  //after clicking the year button dates will set to year
  const handleYear = () => {
    setDate(yearDate);
  };

  //after clicking the month button dates will set to month
  const handleMonth = () => {
    setDate(monthDate);
  };
  //after clicking the half yearly button dates will set to month

  const handleFinancialYear = () => {
    // const financialDateRange = getFinancialYearDates(monthDate);
    const financialDateRange = getFinancialYearStartAndEndDates()
    setFinancialYearDateRange([
      financialDateRange.firstDate,
      financialDateRange.lastDate,
    ]);
    setDate(new Date());

  };
  //after clicking the quarterly button dates will set to month

  const moveToNextFinancialYear = () => {
    setFinancialYearDateRange((prevRange) => {
      const [startDate, endDate] = prevRange;
      const {firstDate,lastDate} = getFinancialYearStartAndEndDates()
      if (startDate && endDate) {

        const nextStartDate = new Date(startDate);
        nextStartDate.setFullYear(startDate.getFullYear() + 1);

        const nextEndDate = new Date(endDate);
        nextEndDate.setFullYear(endDate.getFullYear() + 1);

        if(nextStartDate > lastDate) {
          preventUpcomingDate();
          return prevRange; // Keep the previous range if dates are in the future
        }

        return [nextStartDate, nextEndDate];
      }

      // Default fallback if dates are null
      return [null, null];
    });
  };

  //For handling date select component buttons
  const handleDate = (e: any) => {
    let dateTypeForPicker;
    if (e.value === null) {
      dateTypeForPicker = dateType;
      setDateType((prev: any) => {
        return prev;
      });
    } else {
      setDateType(e.value);
      dateTypeForPicker = e.value;
    }

    if (e.value === "month") {
      handleMonth();
     
    } else if (e.value === "year") {
      // handleYear();
      const {firstDate,lastDate} = getYearStartAndEndDates()
      setDate(firstDate)
    } else if (e.value === "halfYearly") {
      // handleHalfYearly();
      const {firstDate,lastDate} = getYearStartAndEndDates()
      setDate(firstDate)
    } else if (e.value === "quarterly") {
      // handleQuarterly();
      const {firstDate,lastDate} = getYearStartAndEndDates()
      setDate(firstDate)
    }
    else if (e.value === "financialYear") {
      handleFinancialYear()
    }
  };

  //For previous date button
  const handlePrevDate = (type: string, date: any) => {
    if (type === "month") {
      const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      firstDay.setMonth(firstDay.getMonth() - 1);
      setDate(firstDay);
      setMonthDate(firstDay);
    } else if (type === "year" || type === "halfYearly" || type === "quarterly") {
      const prevY = new Date(date.setFullYear(date.getFullYear() - 1));
      setDate(prevY);
      setYearDate(prevY);
    } else if (type === "financialYear") {

      moveToPreviousFinancialYear()
      setDate(financialYearDateRange[0])
    }
    else if (type === "halfYearly") {
      const prevHalfYearDates = getPreviousHalfYearDates(date); // Get previous half-year dates
      setHalfYearlyDateRange([
        prevHalfYearDates.startDate,
        prevHalfYearDates.endDate,
      ]);
      setDate(prevHalfYearDates.startDate);
    } else if (type === "quarterly") {
      const prevQuarterDates = getPreviousQuarterDates(date);
      setQuarterlyDateRange([
        prevQuarterDates.startDate,
        prevQuarterDates.endDate,
      ]);
      setDate(prevQuarterDates.startDate);
    }
  };

  //For next date button
  const handleNextDate = (type: string, date: any) => {
    const today = new Date(); // Current date to compare against
    if (type === "month") {
      const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      firstDay.setMonth(firstDay?.getMonth() + 1);

      //if the date is upcoming date then set it current date
      if (firstDay.getFullYear()>today.getFullYear() || firstDay.getFullYear()===today.getFullYear()&& firstDay.getMonth()>=today.getMonth()) {
        setDate((prev: any) => {
          const newDate = prev;
          return newDate;
        });
        toast.current.show({
          severity: "warn",
          summary: "Sorry!",
          detail: "You can't go to the current and upcoming months!",
          life: 3000,
        });
        return;
      }
      setDate(firstDay);
      setMonthDate(firstDay);
    } else if (type === "year" || type === "halfYearly" || type === "quarterly") {
  
      const nextY = new Date(date);
      nextY.setFullYear(nextY.getFullYear() + 1);
  
      // Get the allowed year range based on the current month
      const { lastDate } = getYearStartAndEndDates();
  
      // Prevent navigating beyond the allowed year range
      if (nextY > lastDate) {
        preventUpcomingDate();
        return;
      }
  
      setDate(nextY);
      setYearDate(nextY);
    }
    else if (type === "financialYear") {
      const nextQuarterDates = moveToNextFinancialYear();
      setDate(financialYearDateRange[0]);
    }

  };

  const preventUpcomingDate = () => {
    setDate((prev: any) => prev); // Reset to the current state
    toast.current.show({
      severity: "warn",
      summary: "Sorry!",
      detail: "You can't go to the current and upcoming periods!",
      life: 3000,
    });
  };

  //This function will decide which calender will show according to the dateType
  const handleCalender = () => {
    if (dateType === "month") {
      setCalender(
        <DatePicker
          selected={date}
          customInput={<CustomInput />}
          value={date}
          onChange={(e: any) =>{
            const today = new Date();
            if (e.getFullYear()>today.getFullYear() || e.getFullYear()===today.getFullYear()&& e.getMonth()>=today.getMonth()) {
              preventUpcomingDate();
              return; // Prevent further actions if the date is in the future
            }
             setDate(e)
            }}
          dateFormat="MMMM yyyy"
          showMonthYearPicker
        />
      );
      setMonthDate(date);
    }
    else if (dateType === "financialYear") {
      setCalender(
        <DatePicker
          selectsRange
          customInput={<CustomInput />}
          startDate={financialYearDateRange[0]}
          endDate={financialYearDateRange[1]}
          onChange={(range: [Date, Date]) => {
            if (range[0] || range[1]) {
              const updatedFinancialYear = getFinancialYearDates(range[0]);
              const {firstDate,lastDate} = getFinancialYearStartAndEndDates()
              if ( updatedFinancialYear.endDate > lastDate) {
                preventUpcomingDate();
                return; // Prevent further actions if the date is in the future
              }
              setFinancialYearDateRange([
                updatedFinancialYear.startDate,
                updatedFinancialYear.endDate,
              ]);
              setDate(range[0]); // Update the primary date state
            }
          }}
          dateFormat="MMMM yyyy"
        />
      );
    }

    else if (dateType === "year" || dateType === "halfYearly" || dateType === "quarterly") {
      setCalender(
        <DatePicker
          selected={date}
          value={date}
          customInput={<CustomInput />}
          onChange={(e) =>{ 
            const {firstDate,lastDate} = getYearStartAndEndDates()
            if (e &&(e.getFullYear() > lastDate.getFullYear())) {
              preventUpcomingDate();
              return;
            }
            setDate(e)
          }}
          dateFormat="yyyy"
          showYearPicker
        />
      );
      setYearDate(date);
    }
  };

  const getDateType = (dateType: string) => {
    let dateTypeForTracker = "0";
    switch (dateType) {
      case "month":
        dateTypeForTracker = "0";
        break;
      case "year":
        dateTypeForTracker = "1";
        break;
      case "halfYearly":
        dateTypeForTracker = "2";
        break;
      case "quarterly":
        dateTypeForTracker = "3";
        break;
      case "financialYear":
        dateTypeForTracker = "4";
        break;
    }
    return dateTypeForTracker
  }


  const handleExpandIcon = async (rowData: PerformanceMonthlyReportDto) => {
    if (expandedRow === rowData.EmployeeCode) {
      setExpandedRow("");
      setReportData((prev: any) => {
        const newReport = [...prev].filter((kp) => kp.isKPIPresent === false);
        return newReport;
      });
      return;
    } else {
      setExpandedRow(rowData?.EmployeeCode);
    }

    const isHistoryPresent: PerformanceMonthlyReportDto | undefined =
      historyOfReportData.find(
        (hReport) => hReport.EmployeeCode === rowData?.EmployeeCode
      );

    if (isHistoryPresent?.kpiReport) {
      setReportData((prev: any) => {
        const newReport = [...prev].filter((kp) => kp.isKPIPresent === false);
        let indexOfEmpCode: number = newReport.findIndex(
          (x) => x.EmployeeCode === rowData?.EmployeeCode
        );

        isHistoryPresent?.kpiReport?.forEach((kpi) => {
          kpi.isKPIPresent = true;
          newReport.splice(indexOfEmpCode + 1, 0, kpi);
          indexOfEmpCode++;
        });
        return newReport;
      });
      return;
    }


    const input: PerformanceReportInputDto = {
      dateType: dateType === "month" ? "0" : "1",
      startDate: createStartAndEndDate(date).startDate,
      endDate: createStartAndEndDate(date).endDate,
      employeeCodes: [rowData?.EmployeeCode],
    };

    const kpiRes: PerformanceMonthlyReportDto[] =
      await getPerformanceReportByKPI(dispatch, input);

    setReportData((prev: any) => {
      const newReport = [...prev].filter((kp) => kp.isKPIPresent === false);
      let indexOfEmpCode: number = newReport.findIndex(
        (x) => x.EmployeeCode === rowData?.EmployeeCode
      );

      kpiRes.forEach((kpi) => {
        kpi.isKPIPresent = true;
        newReport.splice(indexOfEmpCode + 1, 0, kpi);
        indexOfEmpCode++;
      });
      return newReport;
    });
    setHistoryOfReportData((prev) => {
      const newReport = [...prev];
      const empReport: PerformanceMonthlyReportDto | undefined = newReport.find(
        (x) => x.EmployeeCode === rowData?.EmployeeCode
      );

      if (empReport) {
        empReport.kpiReport = kpiRes;
      }

      return newReport;
    });
  };

  const handleExcelData = async () => {
    setExcelLoader(true);
    setExcelBtn(true);
    try {
      const input: PerformanceReportInputDto = {
        dateType: dateType === "month" ? "0" : "1",
        startDate: createStartAndEndDate(date).startDate,
        endDate: createStartAndEndDate(date).endDate,
        employeeCodes: createEmpAndProjArr(selectedEmployee as string[]),
      };
      const headers = {
        Accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        access_token: "Bearer " + localStorage.getItem("token"),
      };

      const response = await instance.post(
        "/performance/getPerformanceExcelReport",
        input,
        {
          headers,
          responseType: "blob",
        }
      );

      if (response) {
        setExcelLoader(false);
        setExcelBtn(false);
      }
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "PerformanceReport");
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error(error);
    }
  };

  //#endregion

  //#region all Templates
  const CustomInput = forwardRef(({ value, onClick }: any, ref: any) => (
    <div className="example-custom-input" onClick={onClick} ref={ref}>
      {value}
    </div>
  ));

  const endToolbar = () => {
    return (
      <div className="report-start-tool p-report-header flex-wrap w-100">
        <Button
          icon={<SiMicrosoftexcel size={20} />}
          onClick={handleExcelData}
          className="excel-btn"
          disabled={excelBtn}
        />
        {excelLoader && (
          <ProgressSpinner
            style={{ width: "20px", height: "20px" }}
            strokeWidth="7"
            fill="var(--surface-ground)"
            animationDuration=".5s"
          />
        )}
        <MultiSelect
          value={selectedEmployee}
          filter
          resetFilterOnHide={true}
          disabled={disableSelectBtn}
          onChange={(e) => setSelectedEmployee(e.value)}
          onHide={getAllReport}
          options={employeeOptions}
          placeholder={localization?.SelectEmployees || "Select Employees"}
          className="w-full md:w-14rem select_width_issue"
          style={{ width: "11rem" }}
          display="chip"
          panelStyle={{
            backgroundColor: mode?.backgroundSecondaryColor,
            color: mode?.color,
          }}
        />
        <SelectButton
          value={dateType}
          onChange={(e) => handleDate(e)}
          optionLabel="name"
          options={buttons}
          disabled={disableSelectBtn}
        />

        {/* visible input calender */}

        <div className="date-input border_primary rounded-3">
          <Button
            icon={<AiOutlineLeft />}
            onClick={() => handlePrevDate(dateType, date)}
            className="date-comp-prev-btn"
            disabled={disableSelectBtn}
          />
          {calender}

          <Button
            icon={<AiOutlineRight />}
            onClick={() => handleNextDate(dateType, date)}
            className="date-comp-next-btn"
            disabled={disableSelectBtn}
          />
        </div>
      </div>
    );
  };

  const employeeNameTemp = (rowData: PerformanceMonthlyReportDto) => {
    return (
      <div
        className="emp-name-col"
        style={{ paddingLeft: rowData?.isKPIPresent ? "3.6rem" : "0" }}
      >
        {!rowData.isKPIPresent && (
          <span onClick={() => handleExpandIcon(rowData)}>
            {expandedRow === rowData?.EmployeeCode ? (
              <FontAwesomeIcon className="col-icon" icon={faChevronDown} />
            ) : (
              <FontAwesomeIcon className="col-icon" icon={faChevronRight} />
            )}
          </span>
        )}
        <span>
          {rowData?.Name}
          {!rowData.isKPIPresent && `(${rowData?.EmployeeCode})`}
        </span>
      </div>
    );
  };

  const scoreTemp = (rowData: any, field: any) => {
    return <span>{rowData[field] === null ? "-" : rowData[field]}</span>;
  };

  //#endregion

  useEffect(() => {
    handleCalender();
  }, [dateType, date, financialYearDateRange, mode?.color, window.location.pathname]);

  useEffect(() => {
    getAllReport();
  }, [dateType, date]);

  useEffect(() => {
    getAllEmployees();
  }, []);

  return (
    <div className="perReort pe-2">
      <div className="p-report-toolbar perreportToolbar">
      <Toast ref={toast} />
      <Toolbar
        end={endToolbar}
        style={{
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
        className="bg-transparent border-0 p-0 mb-3"
      />
      </div>
      

      <div
        className="performanceTableCont border_primary" 
      >
        {dateType === "month" &&
          <DataTable
            className="performance-table report-chart-table"
            value={reportData}
            tableStyle={{}}
          >
            <Column
              bodyStyle={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
              headerStyle={{
                backgroundColor: ThemeColors?.primaryColor,
                color: "white",
              }}
              body={employeeNameTemp}
              header="Employee Name"
            ></Column>
            <Column
              bodyStyle={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
              headerStyle={{
                backgroundColor: ThemeColors?.primaryColor,
                color: "white",
              }}
              field="AvgRating"
              header="Score (%)"
            ></Column>
          </DataTable>
        }
        {dateType == 'year' && <DataTable
          className="performanceReportTable audit-log-table "
          value={reportData}
          style={{}}
        >
          <Column
            bodyStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
            headerStyle={{
              backgroundColor: ThemeColors?.primaryColor,
              color: "white",
            }}
            body={employeeNameTemp}
            header="Employee Name"
          ></Column>

          <Column
            bodyStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
            headerStyle={{
              backgroundColor: ThemeColors?.primaryColor,
              color: "white",
            }}
            body={(e) => scoreTemp(e, "January")}
            header="January Score(%)"
          ></Column>
          <Column
            bodyStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
            headerStyle={{
              backgroundColor: ThemeColors?.primaryColor,
              color: "white",
            }}
            body={(e) => scoreTemp(e, "February")}
            header="February Score(%)"
          ></Column>
          <Column
            bodyStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
            headerStyle={{
              backgroundColor: ThemeColors?.primaryColor,
              color: "white",
            }}
            body={(e) => scoreTemp(e, "March")}
            header="March Score(%)"
          >
            -ddddddddddd
          </Column>
          <Column
            bodyStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
            headerStyle={{
              backgroundColor: ThemeColors?.primaryColor,
              color: "white",
            }}
            body={(e) => scoreTemp(e, "April")}
            header="April Score(%)"
          ></Column>
          <Column
            bodyStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
            headerStyle={{
              backgroundColor: ThemeColors?.primaryColor,
              color: "white",
            }}
            body={(e) => scoreTemp(e, "May")}
            header="May Score(%)"
          ></Column>
          <Column
            bodyStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
            headerStyle={{
              backgroundColor: ThemeColors?.primaryColor,
              color: "white",
            }}
            body={(e) => scoreTemp(e, "June")}
            header="June Score(%)"
          ></Column>
          <Column
            bodyStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
            headerStyle={{
              backgroundColor: ThemeColors?.primaryColor,
              color: "white",
            }}
            body={(e) => scoreTemp(e, "July")}
            header="July Score(%)"
          ></Column>
          <Column
            bodyStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
            headerStyle={{
              backgroundColor: ThemeColors?.primaryColor,
              color: "white",
            }}
            body={(e) => scoreTemp(e, "August")}
            header="August Score(%)"
          ></Column>
          <Column
            bodyStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
            headerStyle={{
              backgroundColor: ThemeColors?.primaryColor,
              color: "white",
            }}
            body={(e) => scoreTemp(e, "September")}
            header="September Score(%)"
          ></Column>
          <Column
            bodyStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
            headerStyle={{
              backgroundColor: ThemeColors?.primaryColor,
              color: "white",
            }}
            body={(e) => scoreTemp(e, "October")}
            header="October Score(%)"
          ></Column>
          <Column
            bodyStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
            headerStyle={{
              backgroundColor: ThemeColors?.primaryColor,
              color: "white",
            }}
            body={(e) => scoreTemp(e, "November")}
            header="November Score(%)"
          ></Column>
          <Column
            bodyStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
            headerStyle={{
              backgroundColor: ThemeColors?.primaryColor,
              color: "white",
            }}
            body={(e) => scoreTemp(e, "December")}
            header="December Score(%)"
          ></Column>

          <Column
            bodyStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
            headerStyle={{
              backgroundColor: ThemeColors?.primaryColor,
              color: "white",
            }}
            field="AvgRating"
            header="Score (%)"
          ></Column>

        </DataTable>}
        {
          dateType == 'halfYearly' && <DataTable
            className="performance-table audit-log-table"
            value={reportData}
            style={{}}
          >
            <Column
              bodyStyle={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
              headerStyle={{
                backgroundColor: ThemeColors?.primaryColor,
                color: "white",
              }}
              body={employeeNameTemp}
              header="Employee Name"
            ></Column>
            <Column
              bodyStyle={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
              headerStyle={{
                backgroundColor: ThemeColors?.primaryColor,
                color: "white",
              }}
              body={(e) => scoreTemp(e, "FirstHalf")}
              header="Jan to June Score(%)"
            ></Column>
            <Column
              bodyStyle={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
              headerStyle={{
                backgroundColor: ThemeColors?.primaryColor,
                color: "white",
              }}
              body={(e) => scoreTemp(e, "SecondHalf")}
              header="July to Dec Score(%)"
            ></Column>
            <Column
              bodyStyle={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
              headerStyle={{
                backgroundColor: ThemeColors?.primaryColor,
                color: "white",
              }}
              field="AvgRating"
              header="Score (%)"
            ></Column>
          </DataTable>
        }
        {
          dateType === "financialYear" && <DataTable
            className="performance-table performanceReportTable audit-log-table"
            value={reportData}
            style={{}}
          >
            <Column
              bodyStyle={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
              headerStyle={{
                backgroundColor: ThemeColors?.primaryColor,
                color: "white",
              }}
              body={employeeNameTemp}
              header="Employee Name"
            ></Column>


            <Column
              bodyStyle={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
              headerStyle={{
                backgroundColor: ThemeColors?.primaryColor,
                color: "white",
              }}
              body={(e) => scoreTemp(e, "April")}
              header="April Score(%)"
            ></Column>
            <Column
              bodyStyle={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
              headerStyle={{
                backgroundColor: ThemeColors?.primaryColor,
                color: "white",
              }}
              body={(e) => scoreTemp(e, "May")}
              header="May Score(%)"
            ></Column>
            <Column
              bodyStyle={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
              headerStyle={{
                backgroundColor: ThemeColors?.primaryColor,
                color: "white",
              }}
              body={(e) => scoreTemp(e, "June")}
              header="June Score(%)"
            ></Column>
            <Column
              bodyStyle={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
              headerStyle={{
                backgroundColor: ThemeColors?.primaryColor,
                color: "white",
              }}
              body={(e) => scoreTemp(e, "July")}
              header="July Score(%)"
            ></Column>
            <Column
              bodyStyle={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
              headerStyle={{
                backgroundColor: ThemeColors?.primaryColor,
                color: "white",
              }}
              body={(e) => scoreTemp(e, "August")}
              header="August Score(%)"
            ></Column>
            <Column
              bodyStyle={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
              headerStyle={{
                backgroundColor: ThemeColors?.primaryColor,
                color: "white",
              }}
              body={(e) => scoreTemp(e, "September")}
              header="September Score(%)"
            ></Column>
            <Column
              bodyStyle={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
              headerStyle={{
                backgroundColor: ThemeColors?.primaryColor,
                color: "white",
              }}
              body={(e) => scoreTemp(e, "October")}
              header="October Score(%)"
            ></Column>
            <Column
              bodyStyle={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
              headerStyle={{
                backgroundColor: ThemeColors?.primaryColor,
                color: "white",
              }}
              body={(e) => scoreTemp(e, "November")}
              header="November Score(%)"
            ></Column>
            <Column
              bodyStyle={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
              headerStyle={{
                backgroundColor: ThemeColors?.primaryColor,
                color: "white",
              }}
              body={(e) => scoreTemp(e, "December")}
              header="December Score(%)"
            ></Column>
            <Column
              bodyStyle={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
              headerStyle={{
                backgroundColor: ThemeColors?.primaryColor,
                color: "white",
              }}
              body={(e) => scoreTemp(e, "January")}
              header="January Score(%)"
            ></Column>
            <Column
              bodyStyle={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
              headerStyle={{
                backgroundColor: ThemeColors?.primaryColor,
                color: "white",
              }}
              body={(e) => scoreTemp(e, "February")}
              header="February Score(%)"
            ></Column>
            <Column
              bodyStyle={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
              headerStyle={{
                backgroundColor: ThemeColors?.primaryColor,
                color: "white",
              }}
              body={(e) => scoreTemp(e, "March")}
              header="March Score(%)"
            >
            </Column>

            <Column
              bodyStyle={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
              headerStyle={{
                backgroundColor: ThemeColors?.primaryColor,
                color: "white",
              }}
              field="AvgRating"
              header="Score (%)"
            ></Column>

          </DataTable>
        }

        {
          dateType == 'quarterly' && <DataTable
            className="performance-table performanceReportTable audit-log-table"
            value={reportData}
            style={{}}
          >
            <Column
              bodyStyle={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
              headerStyle={{
                backgroundColor: ThemeColors?.primaryColor,
                color: "white",
              }}
              body={employeeNameTemp}
              header="Employee Name"
            ></Column>
            <Column
              bodyStyle={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
              headerStyle={{
                backgroundColor: ThemeColors?.primaryColor,
                color: "white",
              }}
              body={(e) => scoreTemp(e, "Q1")}
              header="Jan to Mar Score(%)"
            ></Column>
            <Column
              bodyStyle={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
              headerStyle={{
                backgroundColor: ThemeColors?.primaryColor,
                color: "white",
              }}
              body={(e) => scoreTemp(e, "Q2")}
              header="Apr to Jun Score(%)"
            ></Column>
            <Column
              bodyStyle={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
              headerStyle={{
                backgroundColor: ThemeColors?.primaryColor,
                color: "white",
              }}
              body={(e) => scoreTemp(e, "Q3")}
              header="Jul to Sep Score(%)"
            ></Column>
            <Column
              bodyStyle={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
              headerStyle={{
                backgroundColor: ThemeColors?.primaryColor,
                color: "white",
              }}
              body={(e) => scoreTemp(e, "Q4")}
              header="Oct to Dec Score(%)"
            ></Column>
            <Column
              bodyStyle={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
              headerStyle={{
                backgroundColor: ThemeColors?.primaryColor,
                color: "white",
              }}
              field="AvgRating"
              header="Score (%)"
            ></Column>
          </DataTable>
        }

      </div>

      {/* charts *******************************/}
      {reportData.length > 0 &&<Card
        style={{
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
        // className="mt-3 performance-report-card-cont"
        className="performance-report-card-cont rounded-3 border_primary mt-3 p-0"
      >
        {reportData.length > 0 &&
          (dateType === "month" ? (
            <EmpPerformanceReportMonthlyChart
              data={monthlyChartData}
              date={date}
            />
          ) : (
            <EmpPerformanceReportYearlyChart
              data={reportData as PerformanceYearlyReportDto[]}
              date={dateType === "financialYear" ? financialYearDateRange[0] : date}
              dateType={dateType}
            />
          ))}
      </Card>}
    </div>
  );
};
